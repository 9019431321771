<template>
	<section class="container">
		<div class="row">
			<div class="col-12">
				<BaseTable :items="userLogs" :fields="fields" :isBusy="isBusy">
					<template v-slot:cell(userLogs.timestamp)="data">
						{{ getDateAndTime(data.item.timestamp) }}
					</template>
					<template v-slot:cell(userLogs.operationType)="data">
						{{ data.item.operationType }}
					</template>
					<template v-slot:cell(userLogs.description)="data">
						{{ data.item.description }}
					</template>
					<template v-slot:cell(userLogs.person)="data">
						{{ data.item.person.email }}
					</template>

					<template #cell(data)="row">
						<b-button size="sm" @click="row.toggleDetails" class="mr-2">
							{{ row.detailsShowing ? $t('employeeLogs.hide') : $t('employeeLogs.show') }}
							{{ $t('employeeLogs.details') }}
						</b-button>
					</template>

					<template #row-details="row">
						<b-card>
							{{ row.item.data }}
						</b-card>
					</template>
				</BaseTable>
			</div>
		</div>
	</section>
</template>
<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'
import dateMixin from '@/mixins/dateMixin'
export default {
	name: 'userLogs',
	components: {
		BaseTable,
	},
	mixins: [dateMixin],
	async created() {
		this.$parent.$emit('updateLoader')
		this.isBusy = true
		await this.$store.dispatch('userVuex/getUserLogs', this.userId)
		this.isBusy = false
	},
	data() {
		return {
			disabled: true,
			clicked: false,
			isBusy: false,
			fields: [
				{
					key: 'userLogs.timestamp',
					label: i18n.t('employeeLogs.Timestamp'),
				},
				{
					key: 'userLogs.operationType',
					label: i18n.t('employeeLogs.operationType'),
					sortable: true,
				},
				{
					key: 'userLogs.description',
					label: i18n.t('employeeLogs.description'),
					sortable: true,
				},
				{
					key: 'userLogs.person',
					label: i18n.t('employeeLogs.person'),
					sortable: true,
				},
				{
					key: 'data',
					label: i18n.t('employeeLogs.data'),
					sortable: true,
				},
			],
		}
	},
	computed: {
		userId() {
			return this.$route.params.id
		},
		userLogs() {
			const logs = this.$store.getters['userVuex/userLogs']
			return logs.sort((a, b) => {
				return b.timestamp - a.timestamp
			})
		},
	},
}
</script>
<style lang="scss">
@import '@/styles/pages/companyAdd.scss';
</style>
